import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { ReactComponent as PrevButton } from "../../../assets/images/prevbutton.svg";
import { ReactComponent as NextButton } from "../../../assets/images/nextbutton.svg";
import API from "../../../helpers/Helpers";
import { Backdrop, CircularProgress } from "@mui/material";
import BlogSkeleton from "../../../common/blogSkeleton/BlogSkeleton";
import BlogsCard from "../../../common/blogsCard/BlogsCard";

const Slider = ({ data, loading }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <>
        {data?.length !== 0 || loading ? (
          <div className="flex flex-col items-center justify-center lg:flex-row lg:justify-between absolute w-full -top-20">
            <div className="flex flex-col heading text-center lg:text-left ">
              <h6 className="font-bold leading-tight text-xl mt-0 mb-4 text-primary">
                Blog
              </h6>
              <h4 className="font-bold leading-tight lg:text-4xl  text-3xl mt-0 mb-4 text-secondary">
                Unlock the latest trends/news
              </h4>
            </div>
            {data.length > 3 && (
              <div className="mb-5 flex items-center lg:flex-row lg:justify-between gap-4 lg:mb-0">
                <button
                  className="blog-slider-button-prev flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 transition hover:bg-primary rtl:rotate-180 stroke-black"
                  type="button"
                  onClick={() => {
                    previous();
                  }}
                >
                  <PrevButton />
                </button>
                <button
                  className="blog-slider-button-prev flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 transition hover:bg-primary rtl:rotate-180 stroke-black"
                  type="button"
                  onClick={() => {
                    next();
                  }}
                >
                  <NextButton />
                </button>
              </div>
            )}
          </div>
        ) : null}
      </>
    );
  };

  const loadingItem = Array(3)
    .fill({})
    .map((item, i) => {
      return <BlogSkeleton />;
    });

  return loading ? (
    <Carousel
      deviceType={"desktop"}
      responsive={responsive}
      autoPlay={false}
      autoPlaySpeed={2000}
      infinite={true}
      arrows={false}
      renderButtonGroupOutside={true}
      customButtonGroup={<ButtonGroup />}
      removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
    >
      {loadingItem}
    </Carousel>
  ) : (
    <Carousel
      deviceType={"desktop"}
      responsive={responsive}
      autoPlay={false}
      autoPlaySpeed={2000}
      infinite={true}
      arrows={false}
      renderButtonGroupOutside={true}
      customButtonGroup={<ButtonGroup />}
      removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
    >
      {data?.map((item, index) => {
        return <BlogsCard item={item} slider={true} />;
      })}
    </Carousel>
  );
};
export default Slider;
