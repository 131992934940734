import React, { useEffect } from "react";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import DesktopMacOutlinedIcon from "@mui/icons-material/DesktopMacOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import { ReactComponent as ArrowRight } from "../../../assets/images/arrow-right.svg";
import { useState } from "react";
import API from "../../../helpers/Helpers";

import { Link } from "react-router-dom";
import PositionSkeleton from "../../../common/positionSkeleton/PositionSkeleton";

function Positions() {
  const [careerData, setCareerData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    API.get("/jobs")
      .then(function (response) {
        setCareerData(response?.data?.data || []);
        if (response.data.status) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {careerData.length !== 0 || loading ? (
        <section className="py-20 bg-gradient-to-t from-white/[54%] to-transparent">
          <div className="container">
            <SectionTitle
              title="Open Positions"
              subTitle="Wake Up To A Better Future"
              direction="center"
            />
            <div className="grid md:grid-cols-2 gap-[30px]">
              {loading ? (
                <>
                  <PositionSkeleton />
                  <PositionSkeleton />
                </>
              ) : (
                <>
                  {careerData?.map((item, i) => {
                    return (
                      <div className="bg-white rounded-[32px] py-6 px-4 transition hover:bg-primary/10 sm:p-[30px]">
                        <h4 className="font-mulishExtraBold text-xl text-black mb-10  sm:text-2xl">
                          {item.jobTitle}
                        </h4>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-3">
                            <div>
                              <DesktopMacOutlinedIcon />
                            </div>
                            <div className="font-mulishBold text-black sm:text-lg">
                              <span className="text-primary">
                                {item.jobType}
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center gap-3">
                            <div>
                              <AddTaskOutlinedIcon />
                            </div>
                            <div className="font-mulishBold text-black sm:text-lg">
                              <span className="text-primary">
                                {item.experience}
                              </span>
                            </div>
                          </div>
                          <Link
                            to={`/career/${item.slug}`}
                            aria-label="Icon"
                            className="flex h-11 w-11 items-center justify-center rounded-full bg-[#F3F4F6] transition hover:bg-primary hover:text-white"
                          >
                            <ArrowRight />
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
}

export default Positions;
