import { Skeleton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function PositionSkeleton() {
  return (
    <div className="bg-white rounded-[32px] py-6 px-4 transition hover:bg-primary/10 sm:p-[30px]">
      <h4 className="font-mulishExtraBold text-xl text-black mb-10  sm:text-2xl">
        <Skeleton />
      </h4>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div>
            <Skeleton variant="circular" width={25} height={25} />
          </div>
          <div className="font-mulishBold text-black sm:text-lg">
            <span className="text-primary">
              <Skeleton variant="rounded" width={160} height={20} />
            </span>
          </div>
        </div>
        <div className="flex items-center gap-3">
          <div>
            <Skeleton variant="circular" width={25} height={25} />
          </div>
          <div className="font-mulishBold text-black sm:text-lg">
            <span className="text-primary">
              <Skeleton variant="rounded" width={160} height={20} />
            </span>
          </div>
        </div>
        <Link
          aria-label="Icon"
          className="flex h-11 w-11 items-center justify-center rounded-full bg-[#F3F4F6] transition hover:bg-primary hover:text-white"
        >
          <Skeleton variant="circular" width={40} height={40} />
        </Link>
      </div>
    </div>
  );
}

export default PositionSkeleton;
