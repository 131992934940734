import React from "react";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import formImg from "../../../../assets/images/form-img.png";
import { ReactComponent as User } from "../../../../assets/images/user.svg";
import { ReactComponent as Email } from "../../../../assets/images/email.svg";
import { ReactComponent as Phone } from "../../../../assets/images/phone.svg";
import { ReactComponent as UploadFile } from "../../../../assets/images/upload.svg";
import { useState } from "react";
import API from "../../../../helpers/Helpers";
import { useParams } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { uploadImageToS3 } from "../../../../utils/upload";

function ApplyForm({ passId }) {
  const [loading, setLoading] = React.useState(false);
  const [errorData, setErrorData] = useState([]);
  const [file, setFile] = useState();

  const [formValue, setFormValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    applyFor: passId,
  });

  const handleFileChange = (e) => setFile(e.target.files[0]);

  const validateInput = (inputs) => {
    const maxFileSize = 5 * 1024 * 1024;
    let errors = {};
    if (!inputs.firstName) {
      errors.firstName = "First name is Required";
    }
    if (!inputs.lastName) {
      errors.lastName = "Last Name is Required";
    }
    if (!inputs.email) {
      errors.email = "Email is Required";
    }
    if (!inputs.mobileNo) {
      errors.mobileNo = "Mobile No is Required";
    }
    if (inputs.mobileNo?.length > 10) {
      errors.mobileNo = "Mobile No less then or equal to ten digit";
    }

    if (file) {
      if (file.size > maxFileSize) {
        errors.file = "File size should be less then 5MB";
      }

      if (
        !file.type.startsWith("image/") &&
        file.type !== "application/pdf" &&
        file.type !== "application/msword" &&
        file.type !==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        errors.file = "Only image, pdf, word file allowed";
      }
    } else {
      errors.file = "Resume is Required";
    }

    const noErrors = Object.keys(errors).length === 0;
    if (noErrors) {
      return true;
    } else {
      setErrorData(errors);
      return false;
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const validData = validateInput(formValue);

    if (validData) {
      setLoading(true);
      let resumeFile = "";

      try {
        resumeFile = await uploadImageToS3(file);
      } catch (uploadError) {
        console.error("resume upload error:", uploadError);
        setLoading(false);
        toast.error("resume upload failed");
        return;
      }

      const formData = {
        ...formValue,
        applyFor: passId,
        resume: resumeFile,
        mobileNo: Number(formValue.mobileNo),
      };

      try {
        const response = await API.post(`/jobApply`, formData);

        if (response.data.status) {
          setFormValue({
            firstName: "",
            lastName: "",
            email: "",
            mobileNo: "",
          });
          setErrorData({
            firstName: "",
            lastName: "",
            email: "",
            mobileNo: "",
            file: "",
          });
          document.getElementById("file").value = null;
          toast.success("Applied Successfully");
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("API Error:", error);
        toast.error("Something went wrong with the API request.");
      } finally {
        setLoading(false);
      }
    } else {
      // Client-side validation failed, set error messages accordingly
      // toast.warn("Please provide valid data");
    }
  };

  const handleChange = (event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <>
      <Backdrop
        style={{ zIndex: 10000, color: "rgb(0, 213, 255)" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="py-20">
        <div className="container">
          <div className="lg:flex">
            <div className="text-center lg:text-left mb-9 lg:w-1/3 mr-14">
              <SectionTitle
                title="Fill out the form"
                subTitle="Join Our team"
              />
              <img
                src={formImg}
                alt="form"
                height="274px"
                width="334px"
                className="mx-auto"
                data-aos="fade-right"
                data-aos-duration="1000"
              ></img>
            </div>
            <form
              action="#"
              method="POST"
              onSubmit={handleSubmit}
              className="px-4 py-12 rounded-3xl bg-white lg:w-2/3 lg:px-8"
            >
              <div className="grid gap-10 sm:grid-cols-2">
                <div className="relative">
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={formValue.firstName}
                    onChange={handleChange}
                    error={errorData.firstName ? true : false}
                    className="rounded-2xl w-full border-2 border-gray/20 p-4 font-mulishBold text-gray-400 outline-none transition focus:border-primary"
                  />
                  {errorData.firstName ? (
                    <p className="text-sm mt-[5px] ml-[8px] font-mulishMedium text-red-600">
                      {errorData.firstName}
                    </p>
                  ) : (
                    false
                  )}
                  <User />
                  <label
                    htmlFor="firstName"
                    className="font-mulishBold px-2 -top-3 absolute left-6 text-gray-400 bg-white"
                  >
                    First Name
                  </label>
                </div>
                <div className="relative">
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={formValue.lastName}
                    onChange={handleChange}
                    error={errorData.lastName ? true : false}
                    className="rounded-2xl w-full border-2 border-gray/20 p-4 font-mulishBold text-gray-400 outline-none transition focus:border-primary"
                  />
                  {errorData.lastName ? (
                    <p className="text-sm mt-[5px] ml-[8px] font-mulishMedium text-red-600">
                      {errorData.lastName}
                    </p>
                  ) : (
                    false
                  )}
                  <User />
                  <label
                    htmlFor="lastName"
                    className="font-mulishBold px-2 -top-3 absolute left-6 text-gray-400 bg-white"
                  >
                    Last Name
                  </label>
                </div>
                <div className="relative">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    value={formValue.email}
                    onChange={handleChange}
                    error={errorData.email ? true : false}
                    className="rounded-2xl w-full border-2 border-gray/20 p-4 font-mulishBold text-gray-400 outline-none transition focus:border-primary"
                  />
                  {errorData.email ? (
                    <p className="text-sm mt-[5px] ml-[8px] font-mulishMedium text-red-600">
                      {errorData.email}
                    </p>
                  ) : (
                    false
                  )}
                  <Email />
                  <label
                    htmlFor="email"
                    className="font-mulishBold px-2 -top-3 absolute left-6 text-gray-400 bg-white"
                  >
                    Email
                  </label>
                </div>
                <div className="relative">
                  <input
                    type="tel"
                    name="mobileNo"
                    id="mobileNo"
                    pattern="[6789][0-9]{9}"
                    maxLength="10"
                    value={formValue.mobileNo}
                    onChange={handleChange}
                    error={errorData.mobileNo ? true : false}
                    className="rounded-2xl w-full border-2 border-gray/20 p-4 font-mulishBold text-gray-400 outline-none transition focus:border-primary"
                  />
                  {errorData.mobileNo ? (
                    <p className="text-sm mt-[5px] ml-[8px] font-mulishMedium text-red-600">
                      {errorData.mobileNo}
                    </p>
                  ) : (
                    false
                  )}
                  <Phone />
                  <label
                    htmlFor="mobileNo"
                    className="font-mulishBold px-2 -top-3 absolute left-6 text-gray-400 bg-white"
                  >
                    Mobile No
                  </label>
                </div>
              </div>
              <div className="relative mt-10">
                <input
                  type="file"
                  className="text-sm text-grey-500 rounded-2xl w-full border-2 border-gray/20 p-4 font-mulishBold outline-none transition focus:border-primary
                  file:mr-5 file:py-2 file:px-6
                  file:rounded-full file:border-0
                  file:text-sm file:font-medium
                  file:bg-blue-50 file:text-blue-700
                  hover:file:cursor-pointer hover:file:bg-amber-50
                  hover:file:text-amber-700"
                  name="file"
                  id="file"
                  onChange={handleFileChange}
                />
                {errorData.file ? (
                  <p className="text-sm mt-[5px] ml-[8px] font-mulishMedium text-red-600">
                    {errorData.file}
                  </p>
                ) : (
                  false
                )}
                <UploadFile />
                <label
                  htmlFor="file"
                  className="font-mulishBold px-2 -top-3 absolute left-6 text-gray-400 bg-white"
                >
                  Upload Resume
                </label>
              </div>
              <div className="w-full text-center mt-14 lg:text-right">
                <button
                  type="submit"
                  className="btn rounded-2xl font-mulishExtraBold bg-gray-400 px-12 capitalize  hover:scale-110 text-white transition hover:bg-primary"
                >
                  Submit Application
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default ApplyForm;
