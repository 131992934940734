import React from "react";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import creativeAgency from "../../../assets/images/creative-agency.webp";

function Employment() {
  return (
    <section className="py-20 bg-gradient-to-t from-white/[54%] to-transparent">
      <div className="container">
        <div className="grid items-center gap-8 md:grid-cols-3 lg:grid-cols-2 xl:items-start">
          <div className="order-2 md:order-1 md:col-span-2 lg:col-auto">
            <div className="text-center md:text-left font-semibold">
              <SectionTitle
                title="Careers"
                subTitle="Invest in your future with a career at FutureX"
              />
              <p className="text-gray-500">
                At FutureX, we differentiate ourselves from the rest by our
                commitment to innovation. We value and welcome those who bring
                great ideas and work together to turn those ideas into reality.
                With a global presence, we offer a unique opportunity to work
                with us. Join our team and take the opportunity to work with
                cutting-edge technologies and learn new skills. Don’t miss out
                on this chance for professional growth.
              </p>
            </div>
          </div>
          <img
            src={creativeAgency}
            alt="career-at-futurex"
            height="540px"
            width="540px"
            className="order-1 md:order-2 mx-auto rounded-[32px]"
            data-aos="fade-left"
            data-aos-duration="1000"
          />
        </div>
      </div>
    </section>
  );
}

export default Employment;
