import React from "react";
import "./SectionTitle.css";

function SectionTitle(props) {
  return (
    <div className={`section-title text-${props.direction}`}>
      <h6 className="text-primary font-mulishMedium">{props.title}</h6>
      <h4 className="font-mulishExtraBold">{props.subTitle}</h4>
    </div>
  );
}

export default SectionTitle;
