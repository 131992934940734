import { Skeleton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function BlogSkeleton() {
  return (
    <div className="container">
      <div className="relative gap-3 rounded-3xl mt-[40px] sm:mt-0 border border-transparent bg-white transition duration-500 hover:border-primary hover:bg-primary/20 m-2">
        <Link className="absolute top-0 h-full w-full ltr:left-0 rtl:right-0"></Link>
        <Skeleton
          variant="rectangular"
          height={208}
          className="rounded-t-3xl object-cover"
        />
        <div className="p-5 text-sm">
          <h6 className="font-mulishExtraBold text-primary">
            <Skeleton variant="rounded" width={280} height={20} />
          </h6>
          <h5 className="my-[10px] block text-lg font-mulishExtraBold leading-[23px] text-black line-clamp-2 ">
            <Skeleton variant="rounded" width={160} height={20} />
          </h5>
        </div>
      </div>
    </div>
  );
}

export default BlogSkeleton;
