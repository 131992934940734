import { Backdrop, Chip, CircularProgress, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../../../helpers/Helpers";
import ApplyForm from "./ApplyForm";
import PositionDetailSkeleton from "../../../../common/positionSkeleton/PositionDetailSkeleton";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";

function PositionsDetail() {
  const [careerDetail, setCareerDetail] = useState([]);
  const [rules, setRules] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const { slug } = useParams();

  useEffect(() => {
    setLoading(true);
    API.get(`/jobs/slug/${slug}`)
      .then(function (response) {
        setCareerDetail(response.data);
        setRules(response.data);
        if (response.data.status) {
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went Wrong");
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went Wrong");
      });
  }, []);

  return (
    <>
      {loading ? (
        <>
          <PositionDetailSkeleton />
        </>
      ) : (
        <>
          <section>
            <div className="relative bg-[url(./assets/images/inner-page-hero-bg.webp)] bg-cover bg-bottom bg-no-repeat">
              <div className="container">
                <div className="md:h-[400px] md:flex items-center justify-between py-10 md:py-0">
                  <div className="!text-white text-center md:text-left">
                    <SectionTitle
                      title={`${careerDetail?.data?.jobType} • ${careerDetail?.data?.experience}`}
                      subTitle={careerDetail?.data?.jobTitle}
                    />
                    <a
                      href="#scroll-to-form"
                      className="btn scroll-smooth font-mulishExtraBold inline-block capitalize text-white bg-primary hover:bg-secondary transition hover:scale-110"
                    >
                      Apply for this Job
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="py-20 bg-gradient-to-t from-white/[54%] to-transparent">
            <div className="container">
              <div className="max-w-full leading-normal">
                {careerDetail?.data?.jobSummery ? (
                  <>
                    <h2 className="text-[27px] font-mulishBold text-black mb-[27px]">
                      Job Summary:
                    </h2>
                    <p className="mt-6 mb-6 text-gray-500 text-[18px]">
                      {careerDetail?.data?.jobSummery}
                    </p>
                  </>
                ) : (
                  ""
                )}

                {rules?.data?.rolesNResponsibility.length > 0 ? (
                  <h2 className="text-[27px] font-mulishBold text-black mb-[27px]">
                    Duties and Responsibilities:
                  </h2>
                ) : (
                  ""
                )}
                {rules?.data?.rolesNResponsibility?.map((item, i) => {
                  return (
                    <>
                      <p className="mt-6 mb-6 text-gray-500 text-[18px]">
                        {item}
                      </p>
                    </>
                  );
                })}

                {rules?.data?.requirements.length > 0 ? (
                  <h2 className="text-[27px] font-mulishBold text-black mb-[27px]">
                    Skills and Qualifications:
                  </h2>
                ) : (
                  ""
                )}
                {rules?.data?.requirements?.map((item, i) => {
                  return (
                    <>
                      <p className="mt-6 mb-6 text-gray-500 text-[18px]">
                        {item}
                      </p>
                    </>
                  );
                })}

                {rules?.data?.techStack.length > 0 ? (
                  <h2
                    className={
                      "text-[27px] font-mulishBold text-black mb-[20px] "
                    }
                  >
                    Technology:
                  </h2>
                ) : (
                  ""
                )}
                <div className="flex-none sm:flex" id="scroll-to-form">
                  {rules?.data?.techStack?.map((item, i) => {
                    return (
                      <>
                        <Stack direction="row" sx={{ m: 1 }}>
                          <Chip label={item} color="primary" />
                        </Stack>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      <ApplyForm passId={careerDetail.data?._id} />
    </>
  );
}

export default PositionsDetail;
