import { Skeleton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function ProjectSkeleton() {
  return (
    <div className="relative rounded-3xl border border-transparent bg-white transition duration-500 hover:border-primary hover:bg-primary/20 ">
      <Link className="absolute top-0 left-0 h-full w-full"></Link>
      <Skeleton
        variant="rectangular"
        height={208}
        className="rounded-t-3xl object-cover"
      />

      <div className="p-5 text-sm font-mulishBold">
        <h6 className="mb-1 text-black">
          <Skeleton variant="rounded" width={280} height={20} />
        </h6>
        <p className=" text-gray-500">
          <Skeleton variant="rounded" width={160} height={20} />
        </p>
      </div>
    </div>
  );
}

export default ProjectSkeleton;
