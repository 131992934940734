import React, { useState } from "react";
import form from "../../../assets/images/form-img.png";
import { ReactComponent as Email } from "../../../assets/images/email.svg";
import { ReactComponent as Phone } from "../../../assets/images/phone.svg";
import { ReactComponent as Subject } from "../../../assets/images/subject.svg";
import { ReactComponent as Message } from "../../../assets/images/message.svg";
import { ReactComponent as User } from "../../../assets/images/user.svg";
import { Backdrop, CircularProgress } from "@mui/material";
import API from "../../../helpers/Helpers";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [loading, setLoading] = React.useState(false);
  const [errorData, setErrorData] = useState([]);
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    mobileNo: "",
    subject: "",
    message: "",
  });
  const validateInput = (inputs) => {
    let errors = {};
    if (!inputs.name) {
      errors.name = "Full name is Required";
    }
    if (!inputs.email) {
      errors.email = "Email is Required";
    }
    if (!inputs.mobileNo) {
      errors.mobileNo = "Mobile No is Required";
    }
    if (inputs.mobileNo.length > 10) {
      errors.mobileNo = "Mobile No less then or equal to ten digit";
    }
    if (!inputs.subject) {
      errors.subject = "Subject is Required";
    }
    if (!inputs.message) {
      errors.message = "Message is Required";
    }
    const noErrors = Object.keys(errors).length === 0;
    if (noErrors) {
      return true;
    } else {
      setErrorData(errors);
      return false;
    }
  };

  const headers = {
    "Content-Type": "application/json",
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validData = validateInput(formValue);
    if (validData) {
      const formData = formValue;
      setLoading(true);
      API.post(`/contactRequest`, formData, { headers })
        .then(function (response) {
          setFormValue(response.data);
          if (response.data.status) {
            setLoading(false);
            setFormValue({
              name: "",
              email: "",
              mobileNo: "",
              subject: "",
              message: "",
            });
            setErrorData({
              name: "",
              email: "",
              mobileNo: "",
              subject: "",
              message: "",
            });
            toast.success("Applied Successfully");
          } else {
            setLoading(false);
            toast.error(response.data.message);
          }
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Something wrong");
        });
    } else {
      //   toast.warn("Plz Provide valid data");
    }
  };

  const handleChange = (event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <>
      <Backdrop
        style={{ zIndex: 10000, color: "rgb(0, 213, 255)" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div id="contact-us" className="w-full flex justify-center">
        <div className="container">
          <div className="lg:flex md:gap-6 justify-items-center py-28">
            <div className="lg:w-1/3">
              <div className="px-4 sm:px-0 text-center lg:text-left lg:mb-0">
                <h6 className="font-bold leading-tight text-xl mt-0 mb-4 text-primary">
                  Get In Touch.
                </h6>
                <h4 className="sm:!leading-[50px] font-bold	leading-tight  text-4xl  mt-0 mb-2 text-secondary">
                  Ready to Get Started?
                </h4>
                <img
                  src={form}
                  height="274px"
                  width="334px"
                  alt="form"
                  className="mb-10 mx-auto"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                />
              </div>
            </div>
            <form
              action="#"
              method="POST"
              onSubmit={handleSubmit}
              className="rounded-3xl w-full bg-white px-4 py-12 lg:w-2/3"
            >
              <div className="grid gap-10 sm:grid-cols-2">
                <div className="relative">
                  <div className="flex items-center">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={formValue.name}
                      onChange={handleChange}
                      error={errorData.name ? true : false}
                      className="w-full pr-10 rounded-2xl border-2 border-gray/20 bg-transparent p-4 text-gray-400 font-mulishBold outline-none transition focus:border-primary ltr:pr-12 rtl:pl-12"
                    />

                    <User />
                  </div>
                  <label
                    htmlFor="name"
                    className="absolute bg-white px-2 text-gray-400 font-mulishBold left-4 -top-3"
                  >
                    Full Name
                  </label>
                  {errorData.name ? (
                    <p className="text-sm mt-[5px] ml-[8px] font-mulishMedium text-red-600">
                      {errorData.name}
                    </p>
                  ) : (
                    false
                  )}
                </div>
                <div className="relative">
                  <div className="flex items-center">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={formValue.email}
                      onChange={handleChange}
                      error={errorData.email ? true : false}
                      className="w-full pr-10 rounded-2xl border-2 border-gray/20 bg-transparent p-4 text-gray-400 font-mulishBold outline-none transition focus:border-primary ltr:pr-12 rtl:pl-12"
                    />

                    <Email />
                  </div>
                  <label
                    htmlFor="email"
                    className="absolute bg-white px-2 text-gray-400 font-mulishBold left-4 -top-3"
                  >
                    Email Address
                  </label>
                  {errorData.email ? (
                    <p className="text-sm mt-[5px] ml-[8px] font-mulishMedium text-red-600">
                      {errorData.email}
                    </p>
                  ) : (
                    false
                  )}
                </div>
                <div className="relative">
                  <div className="flex items-center">
                    <input
                      type="tel"
                      name="mobileNo"
                      id="mobileNo"
                      pattern="[6789][0-9]{9}"
                      maxLength="10"
                      value={formValue.mobileNo}
                      onChange={handleChange}
                      error={errorData.mobileNo ? true : false}
                      className="w-full pr-10 rounded-2xl border-2 border-gray/20 bg-transparent p-4 text-gray-400 font-mulishBold outline-none transition focus:border-primary ltr:pr-12 rtl:pl-12"
                    />
                    <Phone />
                  </div>
                  <label
                    htmlFor="mobileNo"
                    className="absolute bg-white px-2 text-gray-400 font-mulishBold left-4 -top-3"
                  >
                    Mobile Number
                  </label>
                  {errorData.mobileNo ? (
                    <p className="text-sm mt-[5px] ml-[8px] font-mulishMedium text-red-600">
                      {errorData.mobileNo}
                    </p>
                  ) : (
                    false
                  )}
                </div>
                <div className="relative">
                  <div className="flex items-center">
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      value={formValue.subject}
                      onChange={handleChange}
                      error={errorData.subject ? true : false}
                      className="w-full pr-10 rounded-2xl border-2 border-gray/20 bg-transparent p-4 text-gray-400 font-mulishBold outline-none transition focus:border-primary ltr:pr-12 rtl:pl-12"
                    />
                    <Subject />
                  </div>
                  <label
                    htmlFor="subject"
                    className="absolute bg-white px-2 text-gray-400 font-mulishBold left-4 -top-3"
                  >
                    Subject
                  </label>
                  {errorData.subject ? (
                    <p className="text-sm mt-[5px] ml-[8px] font-mulishMedium text-red-600">
                      {errorData.subject}
                    </p>
                  ) : (
                    false
                  )}
                </div>
              </div>
              <div className="relative mt-10">
                <div className="flex items-center">
                  <input
                    type="text"
                    name="message"
                    id="message"
                    value={formValue.message}
                    onChange={handleChange}
                    error={errorData.message ? true : false}
                    className="w-full pr-10 rounded-2xl border-2 border-gray/20 bg-transparent p-4 text-gray-400 font-mulishBold outline-none transition focus:border-primary ltr:pr-12 rtl:pl-12"
                  />

                  <Message />
                </div>
                <label
                  htmlFor="message"
                  className="absolute bg-white px-2 text-gray-400 font-mulishBold left-4 -top-3"
                >
                  Message
                </label>
                {errorData.message ? (
                  <p className="text-sm mt-[5px] ml-[8px] font-mulishMedium text-red-600">
                    {errorData.message}
                  </p>
                ) : (
                  false
                )}
              </div>
              <div className="w-full px-4 py-3 sm:px-6 mt-10 text-center lg:text-right">
                <button
                  type="submit"
                  className="btn bg-gray-400 font-mulishExtraBold px-12 capitalize text-white inline-block transition hover:scale-110 hover:bg-primary py-3 rounded-2xl"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
