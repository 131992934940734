import { Skeleton } from "@mui/material";
import React from "react";

function testimonialSkeleton() {
  return (
    <div>
      <>
        <div className="mx-auto  w-full flex overflow-hidden ">
          <div className="relative sm:py-12 md:py-0">
            <div className="items-center gap-4 sm:grid sm:grid-cols-3">
              <div className="col-span-2">
                <div className="relative rounded-2xl p-6">
                  <Skeleton
                    variant="circular"
                    width={45}
                    height={45}
                    style={{ background: "white", marginTop: "-17px" }}
                    className="absolute right-2"
                  />
                  <div className="relative rounded-3xl bg-white/[0.02] p-6">
                    <Skeleton
                      variant="rounded"
                      width={170}
                      height={20}
                      style={{ background: "white" }}
                    />
                    <p className="relative  text-left  pt-8 font-medium italic">
                      <Skeleton
                        variant="rounded"
                        width={600}
                        height={80}
                        style={{ background: "white" }}
                      />
                    </p>
                    <div className="mt-12 text-left">
                      <h5 className="font-extrabold text-primary mb-3">
                        <Skeleton
                          variant="rounded"
                          width={170}
                          height={20}
                          style={{ background: "white" }}
                        />
                      </h5>
                      <h6 className="text-sm font-bold italic text-slate-500">
                        <Skeleton
                          variant="rounded"
                          width={150}
                          height={20}
                          style={{ background: "white" }}
                        />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mx-auto mt-4 h-20 w-20 max-w-[255px] overflow-hidden rounded-full sm:mt-0 sm:h-auto sm:w-auto ">
                <Skeleton
                  variant="rectangular"
                  height={375}
                  width={250}
                  style={{ background: "white" }}
                  className="h-full w-full object-cover object-top"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default testimonialSkeleton;
