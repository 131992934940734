import React from "react";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";

const sectionData = [
  {
    itemStep: "01",
    itemTitle: "Assess Needs",
    itemSubTitle:
      "We analyze your needs, objectives & align our services accordingly.",
  },
  {
    itemStep: "02",
    itemTitle: "Design & Develop",
    itemSubTitle:
      "Our team creates innovative, scalable & flexible software solutions.",
  },
  {
    itemStep: "03",
    itemTitle: "Test & Deploy",
    itemSubTitle:
      "We ensure reliable, user-friendly software through testing & deployment.",
  },
];
function ProjectSteps() {
  return (
    <section className="relative py-[56px] lg:py-[100px] xl:-mt-[100px]">
      <div className="absolute top-0 z-[1] h-full w-full xl:rounded-r-[32px] xl:w-[85%] bg-white"></div>
      <div className="absolute h-full w-full hidden rounded-r-[32px] -top-3 left-[0.75rem] bg-white/50 xl:w-[85%]  xl:inline-block"></div>
      <div className="container relative z-[2] xl:pr-40">
        <div className="lg:w-[75%] text-center lg:text-left">
          <SectionTitle
            title="How it works?"
            subTitle="Transforming your ideas into reality in 3 simple steps."
          />
          <p
            className="font-mulishBold pb-10 text-lg mt-[-25px]"
            style={{ color: "rgb(119, 128, 161)" }}
          >
            Discover the essential steps to converting your innovative ideas
            into tangible digital solutions.
          </p>
        </div>
        <div className="grid gap-[30px] sm:grid-cols-2 lg:grid-cols-3">
          {sectionData.map((item, i) => {
            return (
              <div data-aos="fade-up" data-aos-duration="1000">
                <div className="flex py-6 px-5 items-start rounded-[10px] gap-[10px] transition bg-primary/10 hover:bg-transparent">
                  <span className="flex items-center justify-center rounded-full text-white text-lg h-[50px] w-[50px] min-w-[50px] bg-primary">
                    {item.itemStep}
                  </span>
                  <div>
                    <h6 className="font-bold mb-1 text-primary">
                      {item.itemTitle}
                    </h6>
                    <p className="font-mulishBold text-sm">
                      {item.itemSubTitle}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default ProjectSteps;
