import React from "react";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { ReactComponent as AboutHero } from "../../assets/images/about-hero.svg";
import CompanyFacts from "../Home/CompanyFacts/CompanyFacts";
import JoinTeam from "./JoinTeam/JoinTeam";
import ProjectSteps from "./ProjectSteps/ProjectSteps";
import { Helmet } from "react-helmet";

function AboutUs() {
  return (
    <>
      <Helmet>
        <title>
          About FutureX - Transforming Your Digital Needs with Expert Solutions
        </title>
      </Helmet>
      <div className="relative bg-[url(./assets/images/inner-page-hero-bg.webp)] bg-cover bg-bottom bg-no-repeat">
        <div className="container">
          <div className="md:h-[400px] md:flex items-center justify-between py-10 md:py-0">
            <div className="!text-white text-center md:text-left">
              <SectionTitle title="About Us" subTitle="About FutureX" />
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="1000"
              className="h-[282px] w-[273px] lg:w-[374px]"
            >
              <AboutHero />
            </div>
          </div>
        </div>
      </div>
      <JoinTeam />
      <ProjectSteps />
      <CompanyFacts />
    </>
  );
}

export default AboutUs;
