import React, { useEffect, useState } from "react";
import CarouselSlider from "./CarouselSlider";
import API from "../../../helpers/Helpers";

function Testimonial() {
  const [testimonial, setTestimonial] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    API.get("/testimonials")
      .then(function (response) {
        setTestimonial(response.data.data || []);
        if (response.data.status) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  }, []);
  return (
    <>
      {loading || testimonial?.length !== 0 ? (
        <div className="w-full relative  flex justify-center bg-black py-14 lg:py-[100px]">
          <div className="container">
            <div className="heading text-center lg:text-left">
              <h4 className="!text-white font-mulishExtraBold leading-tight text-4xl mt-0 mb-12">
                Feedback from our clients
              </h4>
              <CarouselSlider data={testimonial} loading={loading} />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
export default Testimonial;
