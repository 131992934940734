import React, { useEffect, useState } from "react";
import Slider from "./Slider";
import API from "../../../helpers/Helpers";

const Blog = () => {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    API.get("/blogs")
      .then(function (response) {
        setBlogData(response.data.data || []);
        if (response.data.status) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  }, []);
  return (
    <div
      className={`${
        loading || blogData?.length !== 0 ? "pt-40 pb-16" : "py-0"
      }`}
    >
      <div className="container">
        <div
          className={`d-flex w-full relative ${
            loading || blogData?.length !== 0 ? "pt-16" : "pt-0"
          }`}
        >
          <Slider data={blogData} loading={loading} />
        </div>
      </div>
    </div>
  );
};
export default Blog;
