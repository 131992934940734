import React, { useEffect, useState } from "react";
import { ReactComponent as BlogImage } from "../../../assets/images/blogimage.svg";
import API from "../../../helpers/Helpers";
import { Link } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import BlogsCard from "../../../common/blogsCard/BlogsCard";
import Blog from "./Blog";

const BlogList = () => {
  const [blogListData, setBlogListData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    API.get("/blogs")
      .then(function (response) {
        setBlogListData(response.data.data);
        if (response.data.status) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  }, []);
  return (
    <>
      <Backdrop
        style={{ zIndex: 10000, color: "rgb(0, 213, 255)" }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <div className="relative bg-[url(./assets/images/inner-page-hero-bg.webp)] bg-cover bg-bottom bg-no-repeat">
          <div className="relative">
            <div className="container">
              <div className="items-center justify-between py-10 md:flex md:h-[400px] md:py-0">
                <div className="heading relative mb-0 text-center lg:text-left ltr:md:text-left rtl:md:text-right">
                  <h6 className="font-bold leading-tight text-xl mt-0 mb-4 text-primary ">
                    Blog
                  </h6>
                  <h4 className="!text-white font-bold leading-tight text-[40px] mt-0 mb-4">
                    Unlock the latest trends/news
                  </h4>
                </div>
                <div
                  className="relative mt-6 md:mt-0"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <BlogImage />
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="bg-gradient-to-t from-white/70 to-transparent py-14 md:py-[100px]">
          <div className="container">
            <div className="grid gap-x-[30px] gap-y-10 sm:grid-cols-2 lg:grid-cols-3">
              {blogListData?.map((item, i) => {
                return <BlogsCard item={item} />;
              })}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default BlogList;
