import { Skeleton } from "@mui/material";
import React from "react";

function PositionDetailSkeleton() {
  return (
    <section className="py-20">
      <div className="container">
        <div className="max-w-full leading-normal">
          <h2 className="text-[27px] font-mulishBold text-black mb-[27px]">
            <Skeleton variant="rounded" width={160} height={20} />
          </h2>
          <p className="mt-6 mb-6 text-gray-500 text-[18px]">
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </p>
          <h2 className="text-[27px] font-mulishBold text-black mb-[27px]">
            <Skeleton variant="rounded" width={160} height={20} />
          </h2>

          <p className="mt-6 mb-6 text-gray-500 text-[18px]">
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </p>

          <h2 className="text-[27px] font-mulishBold text-black mb-[27px]">
            <Skeleton variant="rounded" width={160} height={20} />
          </h2>

          <p className="mt-6 mb-6 text-gray-500 text-[18px]">
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </p>

          <h2 className="text-[27px] font-mulishBold text-black mb-[20px]">
            <Skeleton variant="rounded" width={160} height={20} />
          </h2>
          <div className="flex">
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        </div>
      </div>
    </section>
  );
}

export default PositionDetailSkeleton;
