import React from "react";
import Android from "../../../assets/icons/android.webp";
import Apple from "../../../assets/icons/apple.png";
import ReactIcon from "../../../assets/icons/react.webp";
import Flutter from "../../../assets/icons/flutter.png";
import Javascript from "../../../assets/icons/javascript.png";
import Mongodb from "../../../assets/icons/mongodb.webp";
import Mysql from "../../../assets/icons/mysql.webp";
import Nodejs from "../../../assets/icons/nodejs.png";
import Aws from "../../../assets/icons/aws.webp";
import DynamoDB from "../../../assets/icons/dynamodb.webp";
import Postgresql from "../../../assets/icons/postgresql.webp";
import Twilio from "../../../assets/icons/twilio.png";
import OpenAi from "../../../assets/images/OpenAI.svg";
import PineCorn from "../../../assets/images/pinecone-logo.svg";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import "./Technology.css";

const Technologies = () => {
  const data = [
    {icon: OpenAi, name: "OpenAi"},
    {icon: PineCorn, name: "PineCorn"},
    {icon: ReactIcon, name: "ReactIcon"},
    {icon: Android, name: "Android"},
    {icon: Apple, name: "Apple"},
    {icon: Flutter, name: "Flutter"},
    {icon: Javascript, name: "Javascript"},
    {icon: Nodejs, name: "Nodejs"},
    {icon: Mongodb, name: "Mongodb"},
    {icon: Mysql, name: "Mysql"},
    {icon: Postgresql, name: "Postgresql"},
    {icon: DynamoDB, name: "DynamoDB"},
    {icon: Aws, name: "Aws"},
    {icon: Twilio, name: "Twilio"},
  ];

  return (
    <div className="bg-white/50 py-14 lg:py-[100px]">
      <div className="container">
        <SectionTitle
          title="Advancements"
          subTitle="What Technologies Do We Use?"
          direction="center"
        />
        <div className="itemContainer gap-8 sm:gap-4">
          {data.map((item, index) => {
            return (
              <div data-aos="fade-up" data-aos-duration="1000" className="item">
                <div
                  className={`group rounded-[32px] bg-gray-100 py-[40px] px-[40px] sm:py-[50px] sm:px-[50px] transition duration-500 hover:scale-105  hover:drop-shadow-[0_5px_13px_rgba(60,72,88,0.20)]`}
                >
                  <img src={item.icon} alt={item.name} height="512px" width="512px"></img>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Technologies;
