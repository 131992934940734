import React from "react";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import { ReactComponent as WebIcon } from "../../../assets/images/webIcon.svg";
import { ReactComponent as MobileIcon } from "../../../assets/images/mobileIcon.svg";
import { ReactComponent as SoftwareIcon } from "../../../assets/images/softwareIcon.svg";
import { ReactComponent as GenAiIcon } from "../../../assets/images/learning-machine-ai-icon.svg";
import { ReactComponent as UiUxIcon } from "../../../assets/images/web-design-icon.svg";
import { ReactComponent as DevOpsIcon } from "../../../assets/images/devops-icon.svg";

import { NavLink } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const serviceData = [
  {
    icon: <WebIcon />,
    title: "Web Development",
    subTitle:
      "With modern web tech, we build innovative websites to deliver a rich, superior user experience",
    link: "/#",
  },
  {
    icon: <MobileIcon />,
    title: "Mobile App Development",
    subTitle:
      "Customized design, seamless UX, rapid coding, platform compatibility, & app store optimization",
    link: "/#",
  },
  {
    icon: <GenAiIcon /> ,
    title: "Generative AI",
    subTitle:
      "Elevate your business with AI - innovative solutions for content, decisions & insights",
    link: "/#",
  },
  {
    icon: <SoftwareIcon />,
    title: "Custom Software Development",
    subTitle:
      "We help to choose the right technologies as per the business and industry needs",
    link: "/#",
  },
  {
    icon: <UiUxIcon />,
    title: "UI/UX Design",
    subTitle:
      "Design solutions driven by Design Thinking and Human-Computer Interaction frameworks",
    link: "/#",
  },
  {
    icon: <DevOpsIcon />,
    title: "DevOps services",
    subTitle:
      "Setting up infrastructure and CI/CD pipelines, monitoring systems, and infra-level security solutions",
    link: "/#",
  },
];
const Services = () => {
  return (
    <>
      <section
        id="services"
        className="bg-[url(./assets/images/service-bg.png)] bg-cover bg-center bg-no-repeat py-20"
      >
        <div className="container">
          <SectionTitle
            title="High - Impact Services"
            subTitle="We help Brands with"
            direction="center"
          />
          <div className="grid gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {serviceData.map((item, i) => {
              return (
                <>
                  <div
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    // data-aos-once="true"
                  >
                    <div
                      className="group rounded-3xl bg-white px-6 py-8 transition duration-500 hover:bg-primary/20"
                      style={{
                        boxShadow: "-20px 30px 70px rgba(219, 222, 225, 0.4)",
                      }}
                    >
                      <div
                        className="flex h-14 w-14 items-center justify-center rounded-full bg-primary transition group-hover:bg-black"
                        style={{
                          boxShadow: "0px 10px 20px #01C6F040",
                        }}
                      >
                        {item.icon}
                      </div>
                      <NavLink
                        to={item.link}
                      >
                        <h1 className="my-4 inline-block text-[20px] font-mulishExtraBold text-black active">
                          {item.title}
                        </h1>
                      </NavLink>
                      <p className="text-lg font-semibold transition line-clamp-3 text-gray-500">
                        {item.subTitle}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
