import React, { useEffect } from "react";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import { useState } from "react";
import { Link } from "react-router-dom";
import API from "../../../helpers/Helpers";
import ProjectSkeleton from "../../../common/portfolioSkeleton/ProjectSkeleton";

function OurProjects() {
  const [menuData, setMenuData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    API.get("/portfolios")
      .then(function (response) {
        setMenuData(response.data.data || []);
        if (response.data.status) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {menuData?.length !== 0 || loading ? (
        <section id="OurProjects" className="py-20">
          <div className="container">
            <SectionTitle
              subTitle="Some of our finest work."
              direction="left"
            />
            <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {loading ? (
                <>
                  <ProjectSkeleton />
                  <ProjectSkeleton />
                  <ProjectSkeleton />
                </>
              ) : (
                <>
                  {menuData?.map((item, i) => {
                    return (
                      <>
                        <div>
                          <div className="relative rounded-3xl border border-transparent bg-white transition duration-500 hover:border-primary hover:bg-primary/20 ">
                            <Link
                              to={`/portfolios/${item.slug}`}
                              className="absolute top-0 left-0 h-full w-full"
                            ></Link>
                            <img
                              src={item.thumbnailSmall}
                              alt="project-1"
                              className="h-52 w-full rounded-t-3xl object-cover"
                            />
                            <div className="p-5 text-sm font-mulishBold">
                              <h6 className="mb-1 text-black">{item.title}</h6>
                              <p className=" text-gray-500">
                                {item.projectType}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
}

export default OurProjects;
