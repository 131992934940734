import React from "react";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { ReactComponent as CareerHero } from "../../assets/images/career-hero.svg";
import AmazingPerks from "./AmazingPerks/AmazingPerks";
import Positions from "./Positions/Positions";
import Employment from "./Employment/Employment";
import { Helmet } from "react-helmet";

function Career() {
  return (
    <>
      <Helmet>
        <title>FutureX Career - Join our team at FutureX</title>
      </Helmet>
      <div className="relative bg-[url(./assets/images/inner-page-hero-bg.webp)] bg-cover bg-bottom bg-no-repeat">
        <div className="container">
          <div className="md:h-[400px] md:flex items-center justify-between py-10 md:py-0">
            <div className="!text-white text-center md:text-left">
              <SectionTitle
                title="Career"
                subTitle="Join our team at FutureX"
              />
            </div>
            <div data-aos="fade-left" data-aos-duration="1000">
              <CareerHero />
            </div>
          </div>
        </div>
      </div>
      <Employment />
      {/* <AmazingPerks /> */}
      <Positions />
    </>
  );
}

export default Career;
