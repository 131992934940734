import React from "react";
import { Link } from "react-router-dom";

function BlogsCard({ item, slider }) {
  return (
    <>
      <div>
        <div className="container">
          <div className="relative gap-3 rounded-3xl mt-[40px] sm:mt-0 border border-transparent bg-white transition duration-500 hover:border-primary hover:bg-primary/20 m-2">
            <Link
              className="absolute top-0 h-full w-full ltr:left-0 rtl:right-0"
              to={`${slider ? "blogs/" : ""}${item?.slug}`}
            ></Link>
            <img
              src={item?.blogImage}
              alt="blog-1"
              className="h-52 w-full rounded-t-3xl object-cover"
            />
            <div className="p-5 text-sm">
              <h6 className="font-mulishExtraBold text-primary">
                {item?.blogType}
              </h6>
              <h5 className="my-[10px] block text-lg font-mulishExtraBold leading-[23px] text-black line-clamp-2 ">
                {item?.blogTitle}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogsCard;
