import React, { useEffect, useState } from "react";
import Card from "./Card";
import Carousel from "react-multi-carousel";
import { ReactComponent as PrevButton } from "../../../assets/images/prevbutton.svg";
import { ReactComponent as NextButton } from "../../../assets/images/nextbutton.svg";
import { Backdrop, CircularProgress } from "@mui/material";
import API from "../../../helpers/Helpers";
import TestimonialSkeleton from "../../../common/testimonialSkeleton/testimonialSkeleton";

const CarouselSlider = ({ data, loading }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="flex w-full h-10 justify-center">
        <div className="mt-5 flex items-center justify-center gap-4 right-1/3 left-1/3 sm:absolute sm:mt-0 sm:justify-end false">
          <a
            className="text-sm font-mulishExtraBold text-white transition hover:text-primary"
            href="/#"
          >
            View All
          </a>
          <button
            type="button"
            aria-label="Button"
            onClick={() => {
              previous();
            }}
            className="mt-0 flex h-10 w-10 items-center justify-center rounded-full bg-white/5 transition after:hidden after:text-[0px] hover:bg-primary rtl:rotate-180 stroke-white"
          >
            <PrevButton />
          </button>
          <button
            type="button"
            aria-label="Button"
            onClick={() => {
              next();
            }}
            className="mt-0 flex h-10 w-10 items-center justify-center rounded-full bg-white/5 transition after:hidden after:text-[0px] hover:bg-primary rtl:rotate-180 stroke-white"
          >
            <NextButton />
          </button>
        </div>
      </div>
    );
  };

  const loadingItem = Array(3)
    .fill({})
    .map((item, i) => {
      return <TestimonialSkeleton />;
    });

  return loading ? (
    <Carousel
      deviceType={"desktop"}
      responsive={responsive}
      autoPlay={true}
      autoPlaySpeed={2000}
      infinite={true}
      arrows={false}
      renderButtonGroupOutside={true}
      customButtonGroup={<ButtonGroup />}
      removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
    >
      {loadingItem}
    </Carousel>
  ) : (
    <Carousel
      deviceType={"desktop"}
      responsive={responsive}
      autoPlay={true}
      autoPlaySpeed={2000}
      infinite={true}
      arrows={false}
      renderButtonGroupOutside={true}
      customButtonGroup={<ButtonGroup />}
      removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
    >
      {data.map((item, index) => {
        return <Card data={item} />;
      })}
    </Carousel>
  );
};
export default CarouselSlider;
