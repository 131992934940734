import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import { useEffect } from "react";
import Home from "./pages/Home/Home";
import AOS from "aos";
import "aos/dist/aos.css";
import { ReactComponent as RoundedScroll } from "./assets/images/rounded-scroll.svg";
import ScrollToTop from "react-scroll-to-top";
import "./assets/css/App.css";

import Career from "./pages/Career/Career";
import Footer from "./components/Footer/Footer";
import PositionsDetail from "./pages/Career/Positions/PositionsDetail/PositionsDetail";
import { useLocation } from "react-router-dom";
import ProjectDetail from "./pages/Home/OurProjects/ProjectDetail/ProjectDetail";
import BlogDetail from "./pages/Home/BlogsDetails/BlogDetail";
import BlogList from "./pages/Home/Blogs/BlogList";
import Error404 from "./pages/Error404/Error404";
import AboutUs from "./pages/AboutUs/AboutUs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  return (
    <>
      <Navbar />
      <div className="bg-[url(./assets/images/background.webp)] bg-cover bg-center bg-no-repeat">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/career" element={<Career />} />
          <Route path="/career/:slug" element={<PositionsDetail />} />
          <Route path="/portfolios/:slug" element={<ProjectDetail />} />
          <Route path="/blogs" element={<BlogList />} />
          <Route path="/blogs/:slug" element={<BlogDetail />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </div>
      <Footer />

      <ScrollToTop
        smooth
        component={<RoundedScroll />}
        className="scrollToTop"
      />
      <ToastContainer />
    </>
  );
}

export default App;
