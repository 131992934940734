import React from "react";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import { ReactComponent as Health } from "../../../assets/images/health.svg";
import { ReactComponent as Employee } from "../../../assets/images/employee.svg";
import { ReactComponent as Perks } from "../../../assets/images/perks.svg";
import { ReactComponent as Pto } from "../../../assets/images/pto.svg";
import { ReactComponent as Environment } from "../../../assets/images/environment.svg";
import { ReactComponent as Snacks } from "../../../assets/images/snacks.svg";

function AmazingPerks() {
  const amazingPerksData = [
    {
      icon: <Health />,
      title: "Health Insurance",
    },
    {
      icon: <Employee />,
      title: "Employee Allowance",
    },
    {
      icon: <Perks />,
      title: "Perks & Bonuses",
    },
    {
      icon: <Pto />,
      title: "4 weeks PTO",
    },
    {
      icon: <Environment />,
      title: "Flexible Work Environment",
    },
    {
      icon: <Snacks />,
      title: "Healthy Snacks",
    },
  ];

  return (
    <>
      <section className="py-20 bg-gradient-to-t from-white/[54%] to-transparent">
        <div className="container">
          <SectionTitle
            title="Our Amazing Perks"
            subTitle="Great benefits of working at Plurk"
            direction="center"
          />
          <div className="grid gap-[30px] lg:grid-cols-3 sm:grid-cols-2">
            {amazingPerksData.map((item, i) => {
              return (
                <div
                  className="flex items-center p-6 rounded-[32px] border-2 border-white bg-white  gap-3 transition hover:border-primary hover:bg-primary/10 drop-shadow-[5px_10px_60px_rgba(119,128,161,0.08)] sm:justify-center md:p-10"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  {item.icon}
                  <h6 className="font-mulishBold text-black text-xl">{item.title}</h6>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default AmazingPerks;
