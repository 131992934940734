import React, { useEffect, useState } from "react";
import { ReactComponent as Notification } from "../../../assets/images/notification.svg";
import { ReactComponent as Members } from "../../../assets/images/members.svg";
import { ReactComponent as Clients } from "../../../assets/images/clients.svg";
import { ReactComponent as Projects } from "../../../assets/images/projects.svg";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import CountUp from "react-countup";

const companyData = [
  {
    icon: <Notification />,
    text: "Year in Business",
    duration: "1",
    end: "6",
  },
  {
    icon: <Members />,
    text: "Team Members",
    duration: "1",
    end: "10",
  },
  {
    icon: <Clients />,
    text: "Happy Clients",
    duration: "1",
    end: "10",
  },
  {
    icon: <Projects />,
    text: "Project Done",
    duration: "1",
    end: "20",
  },
];
const CompanyFacts = () => {
  const [startCount, setStartCount] = useState(false);

  useEffect(() => {
    setStartCount(true);
  }, []);
  return (
    <>
      <section id="CompanyFacts" className="py-20">
        <div className="container">
          <SectionTitle
            title="Company Facts"
            subTitle="We are proud of our team"
            direction="center"
          />
          <div className="grid gap-[30px] sm:grid-cols-2 lg:grid-cols-4">
            {companyData.map((item, i) => {
              return (
                <>
                  <div data-aos="fade-up" data-aos-duration="1000">
                    <div className="group flex cursor-pointer items-baseline rounded-3xl bg-white py-8 px-6 transition duration-500 hover:bg-primary/10">
                      <div>{item.icon}</div>
                      <div className="pl-4">
                        {startCount ? (
                          <h4 className="pb-2.5 text-4xl font-mulishExtraBold  text-gray-500 leading-none">
                            <CountUp
                              end={item.end}
                              duration={item.duration}
                              enableScrollSpy
                              scrollSpyOnce
                            />
                            +
                          </h4>
                        ) : null}
                        <span className="font-mulishBold text-black">
                          {item.text}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default CompanyFacts;
