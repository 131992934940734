import React, { useEffect, useState } from "react";
import BlueQuote from "../../../assets/images/blue-quote.png";
import { ReactComponent as Rating } from "../../../assets/images/rating.svg";
import { ReactComponent as Quotation } from "../../../assets/images/quotation.svg";

const Card = ({ data }) => {
  return (
    <>
      <div className="mx-auto  w-full flex overflow-hidden ">
        <div className="relative sm:py-12 md:py-0">
          <div className="items-center gap-4 sm:grid sm:grid-cols-3">
            <div className="col-span-2">
              <div className="relative rounded-2xl p-6">
                <img
                  src={BlueQuote}
                  alt="quote"
                  className="absolute right-2 -top-1"
                />
                <div
                  className="relative rounded-3xl bg-white/[0.02] p-6"
                  style={{ width: "650px" }}
                >
                  <Rating />
                  <p className="relative text-left  pt-8 font-medium italic text-white">
                    {data.description}
                    <span className="absolute left-0 top-5 opacity-5">
                      <Quotation />
                    </span>
                  </p>
                  <div className="mt-12 text-left">
                    <h5 className="font-extrabold text-primary">
                      {data.clientName}
                    </h5>
                    <h6 className="text-sm font-bold italic text-slate-500">
                      {data.designation}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-auto mt-4 h-20 w-20 max-w-[255px] overflow-hidden rounded-full bg-white sm:mt-0 sm:h-auto sm:w-auto ">
              <img
                src={data.clientProfileImage}
                alt="testimonial"
                className="h-full w-full object-cover object-top"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Card;
