import { Skeleton } from "@mui/material";
import React from "react";

function ProjectDetailSkeleton() {
  return (
    <>
      <section className="py-20">
        <div className="container">
          <div>
            <h3 className="font-mulishExtraBold text-xl mb-7 sm:text-2xl">
              <Skeleton variant="rounded" width={280} height={20} />
            </h3>
            <div>
              <ul
                className="flex gap-7 md:gap-7 w-[520px] md:w-auto"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <li className="h-16 w-16 rounded-[15px] flex items-center justify-center border border-transparent md:w-20 md:rounded-[32px] md:h-20 transition duration-500 hover:border-primary hover:bg-primary/20">
                  <div>
                    <Skeleton variant="circular" width={82} height={82} />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="py-20">
            <h3 className="font-mulishExtraBold text-xl mb-7 sm:text-2xl">
              <Skeleton variant="rounded" width={280} height={20} />
            </h3>
            <p className="text-gray-500 font-mulishBold leading-[30px] md:text-lg">
              <Skeleton variant="rounded" width={180} height={20} />
            </p>
            <p className="text-gray-500 mt-5 font-mulishBold leading-[30px] md:text-lg">
              <Skeleton variant="rounded" width={180} height={20} />
            </p>
            <p className="text-gray-500 mt-5 font-mulishBold leading-[30px] md:text-lg">
              <Skeleton variant="rounded" width={180} height={20} />
            </p>
            <p className="text-gray-500 mt-5 font-mulishBold leading-[30px] md:text-lg">
              <Skeleton variant="rounded" width={180} height={20} />
            </p>
          </div>
          <div className="grid gap-[30px] grid-cols-1 sm:grid-cols-3">
            <Skeleton
              variant="rectangular"
              height={208}
              className="rounded-3xl object-cover"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default ProjectDetailSkeleton;
