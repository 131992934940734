import AWS from 'aws-sdk';

const BUCKET = 'futurex-prod-web-public';

const REGION = 'ap-south-1';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS,
});

const myBucket = new AWS.S3({
  params: { Bucket: BUCKET },
  region: REGION,
});

const isDevEnvironment = process.env.NODE_ENV === 'development';

export const uploadImageToS3 = async file => {
  try {
    const params = {
      Body: file,
      Bucket: BUCKET,
      Key: `${isDevEnvironment ? 'dev/' : ''}${Date.now()}_${file?.name}`,
    };

    const res = await myBucket.putObject(params).promise();

    if (res?.$response.httpResponse?.statusCode === 200) {
      return `https://${BUCKET}.s3.${REGION}.amazonaws.com/${params?.Key}`;
    } else {
      console.log('error');
    }
  } catch (err) {
    console.log('err: ', err);
  }
};
