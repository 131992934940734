import React from "react";
import logo from "../../assets/images/logo.svg";
import { ReactComponent as Instagram } from "../../assets/images/instagram.svg";
import { ReactComponent as LinkedIn } from "../../assets/images/linkedIn.svg";
import { ReactComponent as Tree } from "../../assets/images/tree.svg";
import { ReactComponent as Facebook } from "../../assets/images/facebook.svg";
import { ReactComponent as Twitter } from "../../assets/images/twitter.svg";
import footerShape from "../../assets/images/footer-shape.png";
import footerShapeDark from "../../assets/images/footer-shape-dark.png";
import "../../assets/css/App.css";
import { HashLink } from "react-router-hash-link";
import "./Footer.css";

function Footer() {
  let data = [
    {
      title: "Quick Menu",
      subMenu: [
        { title: "Home", link: "/" },
        { title: "Services", link: "/#services" },
        { title: "About Us", link: "/about-us" },
        { title: "Career", link: "/career" },
        { title: "Blogs", link: "/blogs" },
      ],
    },
    {
      title: "Services",
      subMenu: [
        { title: "UI/UX Design", link: "/#" },
        { title: " Web Development", link: "/#" },
        { title: " Mobile Development", link: "/#" },
        { title: " Custom Software Development", link: "/#" },
      ],
    },
  ];
  const socialicon = [
    {
      Social: <LinkedIn />,
      link: "https://www.linkedin.com/company/futurexcs/",
      className: "linkedin",
    },
    {
      Social: <Instagram />,
      link: "https://www.instagram.com/futurexcs",
      className: "instagram",
    },
    {
      Social: <Tree />,
      link: "https://linktr.ee/futurexcs",
      className: "tree",
    },
    {
      Social: <Facebook />,
      link: "https://www.facebook.com/futurexcs",
      className: "facebook",
    },
    {
      Social: <Twitter />,
      link: "https://twitter.com/futurexcs",
      className: "twitter",
    },
  ];

  return (
    <footer className="text-left bg-white-100 text-gray-600 py-15">
      <div className="container mx-auto px-6 py-14">
        <div className="grid gap-y-10 gap-x-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1 justify-items-left max-h-max">
          <div className="relative">
            <a href="/">
              <img
                src={logo}
                alt="futurex"
                height="40px"
                width="35px"
                style={{ cursor: "pointer" }}
              />
            </a>

            <ul className="inline-flex flex-item center mt-12 gap-6">
              {socialicon.map((socialItem, i) => {
                return (
                  <li>
                    <a
                      href={socialItem.link}
                      aria-label="socialicon"
                      className={`text-gray-400  inline-block transition hover:scale-110 ${socialItem.className}`}
                    >
                      {socialItem.Social}
                    </a>
                  </li>
                );
              })}
            </ul>
            <img
              className="mt-5 bottom-0 right-0 sm:left-0"
              src={footerShape}
              alt="footer-shape"
            />
          </div>
          {data.map((item, index) => {
            return (
              <div key={index}>
                <h2 className="mb-4 text-lg font-mulishExtraBold text-gray-900">
                  {item.title}
                </h2>
                <ul className="text-gray-500  mb-4 justify-center md:justify-start flex flex-col font-semibold  ">
                  {item.subMenu.map((subItem) => {
                    return (
                      <li className="mb-4">
                        <a
                          href={subItem.link}
                          className="text-gray-400 inline-block transition hover:scale-110 hover:text-primary"
                        >
                          {subItem.title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
          <div>
            <h2 className="mb-4 text-lg font-bold text-gray-900 ">
              Information
            </h2>
            <ul className="text-gray-500  mb-4 justify-center md:justify-start flex flex-col font-semibold  ">
              <li className="mb-4 text-gray-400">
                406, Atlanta Shopping Mall Sudama Chowk, Mota Varachha, Surat,
                Gujarat 394101
              </li>
              <li>
                <a
                  href="tel:9537646564"
                  className="mb-4 text-gray-400 inline-block transition hover:scale-110 hover:text-primary"
                >
                  +91 9537 646564
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="py-5 border-t-2 border-white/5  bg-[#F3F4F6] ">
        <div className="container mx-auto max-w-7xl px-6">
          <div className="items-center justify-between text-center font-semibold text-gray-400 md:flex">
            <div>
              Copyright© <span>{`${new Date().getFullYear()}`}</span>
              <a
                className="text-primary transition hover:text-secondary ml-1"
                href="/"
              >
                FutureX Consulting Services LLP
              </a>
            </div>
            <div>
              Need help? Visit the
              <HashLink
                className="text-primary transition hover:text-secondary ml-1"
                to="/#contact-us"
              >
                Contact Us
              </HashLink>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
