import "./Home.css";
import homeBanner from "../../assets/images/home-banner-icon.svg";
import leftIcon from "../../assets/images/banner-lefticon.png";
import rightIcon from "../../assets/images/banner-rightIcon.png";
import worldMap from "../../assets/images/world-map.webp";
import Services from "./Services/Services";
import CompanyFacts from "./CompanyFacts/CompanyFacts";
import OurProjects from "./OurProjects/OurProjects";
import ContactUs from "./ContactUs/ContactUs";
import Testimonial from "./Testimonial/Testimonial";
import Technology from "./Technology/Technology";
import Blog from "./Blogs/Blog";

function Home() {
  return (
    <>
      <section
        id="home"
        className="hero overflow-hidden bg-[url(./assets/images/hero-banner.png)] bg-cover bg-bottom bg-no-repeat"
      >
        <img
          src={leftIcon}
          alt="banner-lefticon"
          className="absolute left-0 top-20"
        />
        <img
          src={rightIcon}
          alt="banner-rightIcon"
          className="absolute right-0 -top-4"
        />
        <div className="container">
          <div className="relative pt-14 pb-0 lg:pt-20 lg:pb-60 xl:pt-36">
            <div className="relative z-[1] text-center text-white lg:w-3/5 xl:w-1/2">
              <h1 className="text-4xl font-mulishExtraBold leading-normal sm:text-5xl lg:text-[70px] lg:leading-[90px] lg:text-left">
                <span className="italic text-primary">Think</span> Bigger,
                <br />
                Do <span className="italic text-primary">Better</span>
              </h1>
              <p className="text-lg my-8 lg:w-[90%] lg:text-left font-mulishRegular">
                Our mission is to empower businesses and individuals with
                innovative solutions.
              </p>
              <a
                href="/about-us"
                className="btn mx-auto font-mulishExtraBold mt-2 block w-fit text-black lg:mx-0  bg-white hover:bg-primary transition hover:scale-110"
              >
                read more
              </a>
            </div>
            <img
              src={worldMap}
              height="706px"
              width="1160px"
              alt="world-map"
              className="absolute top-5 left-0 w-full"
            />
            <div
              className="bottom-0 mx-auto mt-5 mb-2 w-auto right-0 md:w-[540px] lg:absolute lg:mb-0 lg:mt-0 xl:w-[650px] "
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <img src={homeBanner} alt="img" width="703" height="654" />
            </div>
          </div>
        </div>
      </section>
      <Services />
      <CompanyFacts />
      <Technology />
      <OurProjects />
      {/* <OurTeam /> */}
      <Blog />
      <Testimonial />
      <ContactUs />
    </>
  );
}

export default Home;
