import React, { useEffect, useState } from "react";
import SophiaAvatar from "../../../assets/images/sophia-avtar.png";
import { useParams } from "react-router-dom";
import API from "../../../helpers/Helpers";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";
import { Helmet } from "react-helmet";
import BlogDetailSkeleton from "../../../common/blogSkeleton/BlogDetailSkeleton";
import moment from "moment/moment";

const BlogDetail = () => {
  const [blogDetail, setBlogDetail] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const { slug } = useParams();

  function createMarkup(c) {
    return { __html: c };
  }
  useEffect(() => {
    setLoading(true);
    API.get(`/blogs/slug/${slug}`)
      .then(function (response) {
        setBlogDetail(response.data.data);
        if (response.data.status) {
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went Wrong");
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went Wrong");
      });
  }, []);
  return (
    <>
      <Helmet>
        <title>{blogDetail?.blogTitle}</title>
        <meta name="description" content={blogDetail?.metaDescription} />
      </Helmet>
      <div>
        {loading ? (
          <>
            <BlogDetailSkeleton />
          </>
        ) : (
          <>
            <div className="relative bg-[url(./assets/images/inner-page-hero-bg.webp)] bg-cover bg-bottom bg-no-repeat">
              <div className="relative">
                <div className="container">
                  <div className="relative w-full py-14 md:my-14 md:inline-block md:py-0 lg:my-[128px]">
                    <div className="heading relative mb-8 text-center lg:mb-0 lg:w-1/2 lg:text-left">
                      <h6 className="font-mulishExtraBold text-primary">
                        {blogDetail?.blogType}
                      </h6>
                      <h4 className="!text-white line-clamp-2 text-4xl font-mulishExtraBold pt-4 leading-tight">
                        {blogDetail?.blogTitle}
                      </h4>

                      <ul className="items-center justify-center pt-6 sm:flex lg:justify-start text-slate-500">
                        <li className="relative pr-4 font-semibold sm:text-lg sm:before:h-1">
                          {moment(blogDetail.created_dateTime).format(
                            "DD MMM, YYYY"
                          )}
                        </li>
                        <li className="relative pr-4 font-semibold  sm:text-lg sm:before:h-1">
                          {blogDetail?.estimatedReadTime} mins read
                        </li>
                      </ul>
                    </div>
                    <div
                      className="top-0 mt-6 md:mt-0 lg:absolute right-0 aos-init aos-animate"
                      data-aos="fade-left"
                      data-aos-duration="1000"
                    >
                      <img
                        src={blogDetail?.blogImage}
                        alt="blog-detail-hero"
                        className="mx-auto h-80 rounded-[32px] sm:h-[400px] sm:w-[445px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section className="bg-gradient-to-t from-white to-transparent py-14">
              <div className="container">
                <div
                  className="default-list prose max-w-full mt-12"
                  dangerouslySetInnerHTML={createMarkup(blogDetail?.blogDetail)}
                ></div>
              </div>
            </section>
          </>
        )}
      </div>
    </>
  );
};
export default BlogDetail;
