import React, { useEffect, useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import headerLogo from "../../assets/images/headerLogo.svg";
import { ReactComponent as ToggleIcon } from "../../assets/images/toggle-menu-icon.svg";
import "./Navbar.css";
import { HashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet";

const navData = [
  {
    title: "About Us",
    link: "/about-us",
  },
  {
    title: "Services",
    link: "services",
  },
  {
    title: "Career",
    link: "/career",
  },
  {
    title: "Blogs",
    link: "/blogs",
  },
  {
    title: "Contact Us",
    link: "contact-us",
  },
];
function Navbar() {
  const [toggleBar, setToggleBar] = useState(false);
  const navbarItem = ["About Us", "Career", "Blogs"];

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -90;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const toggleMenu = () => {
    setToggleBar(!toggleBar);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && toggleBar) {
        setToggleBar(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [toggleBar]);

  return (
    <>
      <Helmet>
        <title>FutureX - Software, Web & Mobile Development Company</title>
      </Helmet>
      <div className="sticky top-0 z-50 border-white/5 bg-black border-b-2">
        <div className="container">
          <div className="fix-screen flex items-center justify-between border-gray-100 py-8 lg:justify-start lg:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <NavLink to="/">
                <span className="sr-only">Your Company</span>
                <img
                  src={headerLogo}
                  alt="futurex"
                  height="185px"
                  width="185px"
                />
              </NavLink>
            </div>
            <div className="text-base font-mulishBold hidden items-center  justify-between lg:flex lg:flex-1">
              {navData.map((item, i) => {
                return (
                  <>
                    {navbarItem.includes(item.title) ? (
                      <>
                        <NavLink
                          className=" text-white hover:text-primary inline-block transition hover:scale-110"
                          to={item.link}
                        >
                          <span className="text-base">{item.title}</span>
                        </NavLink>
                      </>
                    ) : (
                      <HashLink
                        to={"/#" + item.link}
                        scroll={(el) => scrollWithOffset(el)}
                        className=" text-white hover:text-primary inline-block transition hover:scale-110"
                      >
                        {item.title}
                      </HashLink>
                    )}
                  </>
                );
              })}
            </div>
            <button
              type="button"
              aria-label="Button"
              className="flex h-10 w-10 items-center justify-center rounded-full bg-primary lg:hidden"
              onClick={toggleMenu}
            >
              <ToggleIcon />
            </button>
          </div>
        </div>

        <div className={toggleBar ? "open-menu " : "close-menu"}>
          <div className="container">
            <div className="divide-y-2 divide-gray-50 rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="px-5 pt-5 pb-6 ">
                <nav className="grid gap-y-8 font-mulishBold divide-y divide-solid">
                  {navData.map((item, i) => {
                    return (
                      <>
                        {navbarItem.includes(item.title) ? (
                          <>
                            <NavLink
                              className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                              onClick={toggleMenu}
                              to={item.link}
                            >
                              <span className="text-base">{item.title}</span>
                            </NavLink>
                          </>
                        ) : (
                          <HashLink
                            to={"/#" + item.link}
                            scroll={(el) => scrollWithOffset(el)}
                            onClick={toggleMenu}
                            className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                          >
                            <span className="text-base">{item.title}</span>
                          </HashLink>
                        )}
                      </>
                    );
                  })}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
