import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../../../helpers/Helpers";
import { ReactComponent as ReactIcon } from "../../../../assets/images/react.svg";
import { ReactComponent as NodeIcon } from "../../../../assets/images/nodejs.svg";
import { ReactComponent as TypescriptIcon } from "../../../../assets/images/typescript.svg";
import { ReactComponent as JavascriptIcon } from "../../../../assets/images/javascript.svg";
import ProjectDetailSkeleton from "../../../../common/portfolioSkeleton/ProjectDetailSkeleton";

function ProjectDetail() {
  const [projectDetail, setProjectDetail] = useState([]);
  const [technology, setTechnology] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const { slug } = useParams();

  useEffect(() => {
    setLoading(true);
    API.get(`/portfolios/slug/${slug}`)
      .then(function (response) {
        setProjectDetail(response.data.data);
        setTechnology(response.data.data);
        if (response.data.status) {
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went Wrong");
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Something went Wrong");
      });
  }, []);
  return (
    <>
      {loading ? (
        <>
          <ProjectDetailSkeleton />
        </>
      ) : (
        <>
          <section>
            <div className="relative bg-[url(./assets/images/inner-page-hero-bg.webp)] bg-cover bg-bottom bg-no-repeat">
              <div className="container">
                <div className="py-10 md:flex items-center md:h-[400px]">
                  <div className="md:w-[725px] mb-0">
                    <h4 className="!text-white font-mulishExtraBold text-3xl lg:text-[40px] leading-[50px]">
                      {projectDetail?.title}
                    </h4>
                    <p className="mt-7 font-mulishMedium text-[#BBC0D0]">
                      {projectDetail?.heading}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="py-20">
            <div className="container">
              <div>
                <h3 className="font-mulishExtraBold text-xl mb-7 sm:text-2xl">
                  Technologies
                </h3>
                <div>
                  <ul
                    className="flex gap-7 md:gap-7 w-[520px] md:w-auto"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    {technology?.techStack?.map((skill, i) => {
                      return (
                        <>
                          <li className="h-16 w-16 rounded-[15px] flex items-center justify-center bg-white border border-transparent md:w-20 md:rounded-[32px] md:h-20 transition duration-500 hover:border-primary hover:bg-primary/20">
                            <div>
                              {skill === "REACT" ? <ReactIcon /> : <></>}
                              {skill === "NODE" ? <NodeIcon /> : <></>}
                              {skill === "REACT-NATIVE" ? <ReactIcon /> : <></>}
                              {skill === "TYPESCRIPT" ? (
                                <TypescriptIcon />
                              ) : (
                                <></>
                              )}
                              {skill === "JAVASCRIPT" ? (
                                <JavascriptIcon />
                              ) : (
                                <></>
                              )}
                            </div>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="py-20">
                <h3 className="font-mulishExtraBold text-xl mb-7 sm:text-2xl">
                  Description
                </h3>
                <p className="default-list text-gray-500 font-mulishBold leading-[30px] md:text-lg">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: technology?.projectDescription,
                    }}
                  ></div>
                </p>
              </div>
              <div className="grid gap-[30px] grid-cols-1 sm:grid-cols-3">
                {technology?.imageList?.map((technologyItem, i) => {
                  return (
                    <>
                      <img
                        src={technologyItem}
                        alt="portfolio-img-1"
                        class="rounded-[32px]"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                      />
                    </>
                  );
                })}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}

export default ProjectDetail;
