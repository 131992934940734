import { Skeleton } from "@mui/material";
import React from "react";

function BlogDetailSkeleton() {
  return (
    <>
      <div>
        <div className="relative">
          <div className="container">
            <div className="relative w-full py-14 md:my-14 md:inline-block md:py-0 lg:my-[128px]">
              <div className="heading relative mb-8 text-center lg:mb-0 lg:w-1/2 lg:text-left">
                <h6 className="font-mulishExtraBold text-primary">
                  <Skeleton variant="rounded" width={320} height={27} />
                </h6>
                <h4 className="!text-white line-clamp-2 text-4xl font-mulishExtraBold pt-4 leading-tight">
                  <Skeleton variant="rounded" width={200} height={20} />
                </h4>

                <ul className="items-center justify-center pt-6 sm:flex lg:justify-start text-slate-500">
                  <li className="flex items-center justify-center mx-auto sm:mx-0 mt-4 h-20 w-20 max-w-[255px] overflow-hidden rounded-full  sm:mt-0 sm:h-auto sm:w-auto">
                    <Skeleton variant="circular" width={40} height={40} />
                    <span className="px-4 font-semibold sm:text-lg">
                      <Skeleton variant="rounded" width={140} height={20} />
                    </span>
                  </li>
                  <li className="relative px-4 font-semibold sm:text-lg sm:before:h-1">
                    <Skeleton variant="rounded" width={140} height={20} />
                  </li>
                  <li className="relative px-4 font-semibold  sm:text-lg sm:before:h-1">
                    <Skeleton variant="rounded" width={140} height={20} />
                  </li>
                </ul>
              </div>
              <div
                className="top-0 mt-6 md:mt-0 lg:absolute right-0 aos-init aos-animate"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <Skeleton
                  variant="rectangular"
                  height={320}
                  className="mx-auto h-80 rounded-[32px] sm:h-[400px] sm:w-[445px]"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-t from-white to-transparent py-14">
          <div className="container">
            <div>
              <Skeleton
                variant="rounded"
                width={220}
                height={14}
                className="prose mt-12"
              />
            </div>
            <div>
              <Skeleton
                variant="rounded"
                width={220}
                height={14}
                className="prose mt-4"
              />
            </div>
            <div>
              <Skeleton
                variant="rounded"
                width={220}
                height={14}
                className="prose mt-4"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogDetailSkeleton;
