import React from "react";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import AboutWho from "../../../assets/images/about-who-bg.png";

function JoinTeam() {
  return (
    <section className="relative lg:pt-[100px] pt-14 lg:pb-48">
      <div className="container relative z-[1]">
        <div className="lg:w-[50%] text-center lg:text-left">
          <SectionTitle
            title="Who we are?"
            subTitle="Transforming Your Digital Needs with Expert Solutions"
          />

          <p
            className="font-mulishBold text-lg mt-[-25px]"
            style={{ color: "rgb(119, 128, 161)" }}
          >
            Welcome to FutureX, your ultimate partner for custom software
            solutions, app and web development. With our dedicated team of
            experts, we create innovative and user-centric digital solutions
            tailored to your specific needs, driving your business to success.
          </p>
          <a
            href="/career"
            className="btn font-mulishExtraBold inline-block mt-10 capitalize text-white bg-primary hover:bg-secondary transition hover:scale-110"
          >
            Join Our Team
          </a>
        </div>
      </div>
      <div
        className="right-0 ml-24 bottom-0 lg:absolute"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <img src={AboutWho} alt="office-team" />
      </div>
    </section>
  );
}

export default JoinTeam;
